import {
  ConversationResponse,
  MessageResponse,
  MessageStatus,
  MessageType,
} from '@just-ai/api/dist/generated/AppsAdapter';
import { Subscription } from 'rxjs';

import { AgentType, ValidationTemplate } from './agents';

export type AgentTemplateResponse = {
  template: string;
  type: string;
  params: { [key: string]: any };
};

export type TextMessagePart = {
  type: 'text';
  text: string;
  localizationKey?: string;
  payload?: Record<string, any>;
};
export type FileMessagePart = {
  type: 'file';
  name?: string;
  url?: string;
  fileId?: string;
  file?: File;
  mimeType?: string;
};
export type LinkMessagePart = { type: 'link'; name?: string; url: string; fileId?: string };
export type AppMessagePart = { type: 'app'; app: AgentTemplateResponse };
export type ImageMessagePart = { type: 'image'; url: string; fileId?: string };
export type AudioMessagePart = { type: 'audio'; url: string; fileId?: string };
export type SystemUpdateMessagePart = {
  type: 'systemAppUpdate';
  newAppParams: Record<string, any>;
  oldAppParams: Record<string, any>;
};
export type AutoContextResetMessagePart = { type: 'autoContextReset' };

export type MessagePart =
  | TextMessagePart
  | FileMessagePart
  | LinkMessagePart
  | AppMessagePart
  | ImageMessagePart
  | AudioMessagePart
  | SystemUpdateMessagePart
  | AutoContextResetMessagePart;

export type CopyMessagePart = TextMessagePart | LinkMessagePart | ImageMessagePart;

export type MessageContent = Array<MessagePart>;

export function isMessageText(message: MessagePart): message is TextMessagePart {
  return message.type === 'text';
}

export function isMessageFile(message: MessagePart): message is FileMessagePart {
  return message.type === 'file';
}

export function isMessageLink(message: MessagePart): message is LinkMessagePart {
  return message.type === 'link';
}

export function isMessageApp(message: MessagePart): message is AppMessagePart {
  return message.type === 'app';
}

export interface Message extends Omit<MessageResponse, 'type' | 'content' | 'meta' | 'status'> {
  type: `${MessageType}`;
  content: MessageContent;
  status: `${MessageStatus}`;
  meta?: { tokensSpent?: number };
  entities?: ValidationTemplate;
}

export interface ChatBody {
  messages: Message[];
  key: string;
  prompt: string;
  temperature: number;
}

export type ConversationContext = {
  fullness: number;
  messagesTruncationMode: boolean;
};

export interface Conversation extends Omit<ConversationResponse, 'history'> {
  config: AgentType;
  messageIsStreaming?: boolean;
  newMessage?: Message;
  defaultTitle?: string;
  history: Message[];
  streamSubscription?: Subscription;
  contextValue: ConversationContext;
}

export interface LinkMessage {
  text: string;
  url: string;
}

export type ChatRequest =
  | { text: string; file?: File; fileId?: never }
  | { text: string; file?: never; fileId?: string };

export interface ChatResponse {
  conversationId: string;
  history: Message[];
  response: {
    text: string;
  };
}
