import React, { memo, useEffect } from 'react';

import cn from 'classnames';
import { NoMoreThen3SyncRequestsTextInform } from 'components/Chat/NoMoreThen3SyncRequestsTextInform';

import { t } from '../../../localization';
import { hideBlinkRequestsWarn } from './signal';
import classes from './styles.module.scss';

export const NoMoreThan3RequestsPopup = memo(({ isShown }: { isShown?: boolean }) => {
  useEffect(() => {
    if (isShown) setTimeout(hideBlinkRequestsWarn, 5000);
  }, [isShown]);

  if (isShown === undefined) return null;

  return (
    <div
      className={cn(classes.NoMoreThan3RequestsBlink, 'animated', {
        [classes.slideOutDown]: !isShown,
        [classes.slideInDown]: isShown,
      })}
    >
      <NoMoreThen3SyncRequestsTextInform text={t('noMoreThan3RequestsToCreateApp')} />
    </div>
  );
});
