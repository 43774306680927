import React from 'react';

import { Item, Gallery } from 'react-photoswipe-gallery';

import 'photoswipe/dist/photoswipe.css';

type ImageGalleryProps = {
  galleryID: string;
  images: { largeURL: string; thumbnailURL: string }[];
  errorHandler?: () => void;
  withExistingGallery?: boolean;
};

export default function ImageGallery(props: ImageGalleryProps) {
  const { errorHandler, withExistingGallery } = props;

  return withExistingGallery ? (
    <>
      {props.images.map((image, index) => (
        <Item
          key={props.galleryID + '-' + index}
          original={image.largeURL}
          thumbnail={image.thumbnailURL}
          width={0.75 * window.innerWidth}
          height={0.75 * window.innerWidth}
          alt=''
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              className='cursor-pointer'
              onClick={open}
              alt=''
              src={image.thumbnailURL}
              onError={errorHandler}
            />
          )}
        </Item>
      ))}
    </>
  ) : (
    <Gallery id={'#' + props.galleryID} withDownloadButton>
      {props.images.map((image, index) => (
        <Item
          key={props.galleryID + '-' + index}
          original={image.largeURL}
          thumbnail={image.thumbnailURL}
          width={0.75 * window.innerWidth}
          height={0.75 * window.innerWidth}
          alt=''
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              className='cursor-pointer'
              onClick={open}
              alt=''
              src={image.thumbnailURL}
              onError={errorHandler}
            />
          )}
        </Item>
      ))}
    </Gallery>
  );
}
