import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ConversationAndAppCreateRequestApp } from '@just-ai/api/src/generated/AppsAdapter';
import { useTranslation } from '@just-ai/just-ui';
import axios, { AxiosError } from 'axios';
import { useLocation } from 'react-router';
import { GA } from 'utils/app/common';

import AppContext, { useAppContext } from '../../contexts/appContext';
import { templates } from '../../models/templates';
import { tourSkipEvent$ } from '../../modules/GuideTourStepper/hooks';
import { goToConversation } from '../../routes';
import useApiService from '../../services/useApiService';
import { AgentType, CreatedAgent, ValidationTemplate } from '../../types/agents';
import { AgentApiError } from '../../types/errors';
import { TemplatesView } from './TemplatesView';

const AgentsPanel = memo(() => {
  const {
    state: { locale },
  } = useAppContext();

  const cancelRefCreating = useRef<AbortController>();
  const cancelRefFile = useRef<AbortController>();

  const [currentTemplate, setCurrentTemplate] = useState<AgentType | CreatedAgent | null>(null);

  const [loading, setLoading] = useState(false);

  const location = useLocation<{ template: AgentType }>();

  const { uploadFile, sendUserActionToAnalytics, createUserChat, prevalidateApp } = useApiService();

  const { t } = useTranslation();

  const templatesList = templates.value?.templatesList as AgentType[];

  const [errorState, setErrorState] = useState<ValidationTemplate>(null);

  const {
    state: { userId },
    handleNewConversation,
    addAlert,
  } = useContext(AppContext);

  useEffect(() => {
    const queryTemplate = location.state?.template;
    if (queryTemplate) {
      setCurrentTemplate(queryTemplate);
      setErrorState(null);
    }
  }, [location]);

  const handleSelectTemplate = useCallback((template: AgentType) => {
    setCurrentTemplate(template);
  }, []);

  const handleAgentCreate = useCallback(
    async (config: AgentType) => {
      setLoading(true);
      setErrorState(null);

      const sendConfig = {
        app: {
          template: config.template,
          params: config.params,
        } as ConversationAndAppCreateRequestApp,
      };

      try {
        cancelRefCreating.current = new AbortController();
        sendUserActionToAnalytics({
          eventName: 'AppSelect',
          eventValue: {
            type: 'app',
            name: config.template,
          },
        });
        const { data: checkResult } = await prevalidateApp(sendConfig.app, cancelRefCreating.current);
        if (!!checkResult.deniedEntities?.length) {
          setErrorState(checkResult.deniedEntities as ValidationTemplate);
          return;
        }
        const { data: res } = await createUserChat(sendConfig, true, cancelRefCreating.current);
        const newConversation = await handleNewConversation(res, config);
        sendUserActionToAnalytics({
          eventName: 'AppStart',
          eventValue: {
            type: 'app',
            name: config.template,
          },
        });
        GA('App_launch', config.template);
        goToConversation(newConversation.id);
        setCurrentTemplate(null);
      } catch (error) {
        const { response } = error as AxiosError<AgentApiError>;
        if (response?.data.error === 'gateway.common.timeout') {
          setCurrentTemplate(null);
          addAlert(t('timeoutError'), 'error');
        }
        if (axios.isAxiosError(error) && error.code !== AxiosError.ERR_CANCELED) {
          addAlert(t(response?.data.error ?? 'defaultError', response?.data.args ?? {}), 'error');
        }
        setCurrentTemplate(null);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [addAlert, createUserChat, handleNewConversation, prevalidateApp, sendUserActionToAnalytics, t]
  );

  const handleFileUpload = useCallback(
    async (data: File) => {
      setLoading(true);
      try {
        cancelRefFile.current = new AbortController();
        const { data: resp } = await uploadFile(data, cancelRefFile.current);
        return { fileId: resp.id, fileName: resp.name, url: resp.url };
      } catch (error) {
        if (axios.isAxiosError(error) && error.code !== AxiosError.ERR_CANCELED) {
          error.response?.data.error
            ? addAlert(t(error.response?.data.error))
            : addAlert(t('fileUploadErr') + ' ' + (error as AxiosError).message);
        }
      } finally {
        setLoading(false);
      }
    },
    [addAlert, t, uploadFile]
  );

  const cancelTemplate = () => {
    if (cancelRefCreating.current) {
      cancelRefCreating.current.abort();
    }
    if (cancelRefFile.current) {
      cancelRefFile.current.abort();
    }
    tourSkipEvent$.next('tour:end');
    setCurrentTemplate(null);
    if (location.state?.template) {
      window.history.replaceState({}, document.title);
    }
    setLoading(false);
    setErrorState(null);
  };

  return (
    <TemplatesView
      isOpenFromTelegram={false}
      locale={locale}
      allTemplates={templatesList}
      onTemplateSelect={handleSelectTemplate}
      currentTemplate={currentTemplate}
      onCancelClick={cancelTemplate}
      loading={loading}
      userId={userId}
      submit={handleAgentCreate}
      handleFileUpload={handleFileUpload}
      errorState={errorState}
    />
  );
});
AgentsPanel.displayName = `memo(AgentsPanel)`;

export default AgentsPanel;
