import { signal } from '@preact/signals-react';

import axios from '../../api/client';
import { CreatedAgent } from '../../types/agents';

export const agents = signal<CreatedAgent[]>([]);
export const agentsLoaded = signal(false);

export const dropAgents = () => {
  agents.value = [];
  agentsLoaded.value = false;
};

export const updateAgent = async (chatId: string, agentData: any) => {
  return await axios.put(`/agent/conversation/${chatId}`, agentData.params);
};

export const createTelegramUserTemplate = async (agentData: any) => {
  const { data } = await axios.post<string>('/direct/agent/template', agentData);
  return data;
};
