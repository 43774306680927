import React, { useCallback } from 'react';

import { ResourceToAccess } from '@just-ai/api/dist/generated/CopilotGateway';
import { Badge, useToggle, useTranslation } from '@just-ai/just-ui';

import { tWithCheck } from '../../../localization';
import { addAlert } from '../../../models/alerts';
import { isRequestsLimitExceeded } from '../../../models/conversations';
import { hasFeature } from '../../../models/currentUser';
import useApiService from '../../../services/useApiService';
import { AgentType, CreatedAgent } from '../../../types/agents';
import { GA } from '../../../utils/app/common';
import RequestFeatureModal, { RequestFeatureModalInputs } from '../../RequestFeatureModal';
import { showBlinkRequestsWarn } from '../NoMoreThan3RequestsBlink/signal';
import styles from './style.module.scss';
import { Props } from './TemplatesList';

export function TemplateItem(props: {
  onTemplateSelect: Props['onTemplateSelect'];
  template: AgentType | CreatedAgent;
}) {
  const { t } = useTranslation();
  const [isRequestModalOpen, openModal, closeModal] = useToggle(false);

  const { sendUserRequestForFeature } = useApiService();

  const sendRequestForTemplate = useCallback(
    async (data: RequestFeatureModalInputs) => {
      await sendUserRequestForFeature({
        ...data,
        industry: t(`Modal_RequestFeature:option:${data.industry}`),
        fullName: data.name,
        resourceType: ResourceToAccess.Agent,
        resourceName: props.template.info.title,
        phone: data.phone.code + data.phone.number,
      });
      addAlert(t('RequestForFeature'), 'success');
      closeModal();
    },
    [closeModal, props.template?.info?.title, sendUserRequestForFeature, t]
  );

  const onTemplateSelectHandle = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      if (props.template.requiredFeature && !hasFeature(props.template.requiredFeature)) {
        openModal();
        return;
      }
      if (isRequestsLimitExceeded.value) return showBlinkRequestsWarn();

      GA('Choose_App', props.template.template);
      props.onTemplateSelect(props.template);
    },
    [openModal, props]
  );

  if (!props.template.info) return null;

  return (
    <>
      <div
        onClick={onTemplateSelectHandle}
        className={styles.templateList__item}
        data-test-id={props.template.template}
      >
        {props.template.label && (
          <Badge color='indigo' pastel text={props.template.label} className={styles.templateList__item__badge} />
        )}
        {props.template.info && (
          <div>
            <div className='flex gap-8'>
              {props.template.info.icon}
              <b>{props.template.info.title}</b>
            </div>
            <p className='mt-2'>{props.template.info.description}</p>
          </div>
        )}
      </div>
      <RequestFeatureModal
        sendRequestForTemplate={sendRequestForTemplate}
        closeModal={closeModal}
        isRequestModalOpen={isRequestModalOpen}
        type='template'
        title={t(`Modal_RequestFeatureFor_${props.template.template}:title`)}
        infoText={
          <div className={styles.templateList__requestFeatureInfo}>
            <p>
              <b>{t(`Modal_RequestFeatureFor_${props.template.template}:header`)}</b>
            </p>
            <p>{t(`Modal_RequestFeatureFor_${props.template.template}:body`)}</p>
            <p>
              {tWithCheck(`Modal_RequestFeatureFor_${props.template.template}:subBody`) ||
                t('Modal_RequestFeatureFor:subBody')}
            </p>
          </div>
        }
      />
    </>
  );
}
