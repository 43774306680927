const packInfo = {
    lib: "src/lib/Icon/CustomIconsPack",
    prefix: "fa",
    iconsNameMap: {
        "faCloudOffline": () => import('../CustomIconsPack/faCloudOffline'),
        "faCloudOk": () => import('../CustomIconsPack/faCloudOk'),
        "faCrosshair": () => import('../CustomIconsPack/faCrosshair'),
        "faCurrentBranch": () => import('../CustomIconsPack/faCurrentBranch'),
        "faEnterSymbol": () => import('../CustomIconsPack/faEnterSymbol'),
        "faIncomingIsland": () => import('../CustomIconsPack/faIncomingIsland'),
        "faIncomingRightArrow": () => import('../CustomIconsPack/faIncomingRightArrow'),
        "faJGTheme": () => import('../CustomIconsPack/faJGTheme'),
        "faJustSelect": () => import('../CustomIconsPack/faJustSelect'),
        "faOutgoingIsland": () => import('../CustomIconsPack/faOutgoingIsland'),
        "faOutgoingRightArrow": () => import('../CustomIconsPack/faOutgoingRightArrow'),
        "faVisibilityBlock": () => import('../CustomIconsPack/faVisibilityBlock'),
        "faVisibilityBlockCollapsed": () => import('../CustomIconsPack/faVisibilityBlockCollapsed'),
        "falQuestionCustom": () => import('../CustomIconsPack/falQuestionCustom'),
        "farCog": () => import('../CustomIconsPack/farCog'),
        "farDrag": () => import('../CustomIconsPack/farDrag'),
        "farJGState": () => import('../CustomIconsPack/farJGState'),
        "fasJGState": () => import('../CustomIconsPack/fasJGState'),
        "fasPaperPlane": () => import('../CustomIconsPack/fasPaperPlane'),
        "fasTimes": () => import('../CustomIconsPack/fasTimes')
    }
};
export default packInfo;
