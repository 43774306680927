import React, { useEffect, useState, useMemo } from 'react';

import { ExpenseField } from '@just-ai/api/dist/generated/CopilotGateway';
import { Spinner, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import { Bar } from 'react-chartjs-2';
import useApiService from 'services/useApiService';

import { useAppContext } from '../../../../contexts/appContext';
import { createChartLabel } from '../utils';
import styles from './styles.module.scss';

export const BarChart = ({ dates }: { dates: Range }) => {
  const [expensesData, setExpensesData] = useState<{ date: string; value: number }[]>();
  const { getExpensesHistory } = useApiService();
  const { state } = useAppContext();

  const { t } = useTranslation();

  const [{ loading }, getChartData] = usePromiseProcessing(
    async () => {
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.Date],
        sort: ['date', 'asc'],
        locale: state.locale,
        size: 100,
      });
      const processedData = data.expenses.map(item => ({
        date: new Date(item.date as unknown as number).toLocaleString(state.locale, { month: 'long', day: 'numeric' }),
        value: item.tokenAmount,
      }));
      setExpensesData(processedData);
    },
    {
      deps: [getExpensesHistory, dates],
    }
  );

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  const chartData = useMemo(() => {
    return {
      labels: expensesData?.map(item => item.date),
      datasets: [
        {
          data: expensesData?.map(item => item.value),
          fill: true,
          backgroundColor: '#7FDB83',
          borderColor: '#7FDB83',
          hoverBackgroundColor: '#7FDB83',
        },
      ],
    };
  }, [expensesData]);

  if (loading) {
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );
  }

  if (!chartData.labels?.length) {
    return <div className='flex items-center justify-center p-5'>{t('Analytics:Expenses:Empty')}</div>;
  }

  return (
    <div className={styles.BarChart}>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          tooltips: {
            displayColors: false,
            backgroundColor: '#6A7178',
            callbacks: {
              label: createChartLabel,
            },
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                  drawBorder: false,
                },
                ticks: {
                  padding: 16,
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                  zeroLineColor: 'transparent',
                },
              },
            ],
          },
        }}
        legend={{ display: false }}
      />
    </div>
  );
};
