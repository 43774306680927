import React from 'react';

import { IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';

type Props = {
  files: File[] | null;
  handleFileRemove: (file: File) => void;
};

export default function Attachments(props: Props) {
  const { files, handleFileRemove } = props;

  return (
    <div className='flex gap-4 pl-2 margin-bottom-8'>
      {files?.map(file => (
        <div className={cn('flex items-center gap-4', styles.chat__fileWrapper)} key={file.name}>
          <p className='text-ellipsis' title={file.name}>
            {file.name}
          </p>
          <IconButton
            name='falTimes'
            flat
            size='sm'
            color='secondary'
            className={styles.chat__fileWrapper_deleteButton}
            onClick={() => handleFileRemove(file)}
          />
        </div>
      ))}
    </div>
  );
}
