import React, { useCallback, useContext } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import { conversations } from 'models/conversations/signals';

import AppContext from '../../contexts/appContext';
import { Conversation } from '../../types/chat';
import Sidebar from '../Sidebar';
import { ChatbarSettings } from './components/ChatbarSettings';
import { Conversations } from './components/Conversations';

export const Chatbar = () => {
  const { t } = useTranslation();

  const {
    state: { showChatbar },
    dispatch: homeDispatch,
  } = useContext(AppContext);

  const handleToggleChatbar = useCallback(() => {
    homeDispatch({ field: 'showChatbar', value: !showChatbar });
    localStorage.setItem('showChatbar', JSON.stringify(!showChatbar));
  }, [homeDispatch, showChatbar]);

  return (
    <Sidebar<Conversation>
      side='left'
      isOpen={showChatbar}
      addItemButtonTitle={t('allTemplates')}
      itemComponent={<Conversations conversations={conversations.value} toggleSidebar={handleToggleChatbar} />}
      items={conversations.value}
      toggleOpen={handleToggleChatbar}
      footerComponent={<ChatbarSettings toggleSidebar={handleToggleChatbar} />}
    />
  );
};
