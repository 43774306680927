import React, { useRef } from 'react';

import {
  IconButton,
  Popover,
  useToggle,
  useOnClickOutside,
  useTranslation,
  Icon,
  Tooltip,
  Button,
} from '@just-ai/just-ui';
import cn from 'classnames';

import { ConversationContext } from '../../types/chat';
import styles from './style.module.scss';

type Props = {
  clearHistory: () => void;
  openSettings: () => void;
  deleteChat: () => void;
  contextValue: ConversationContext;
};

export default function ChatContextValueMobile(props: Props) {
  const { clearHistory, deleteChat, openSettings, contextValue } = props;

  const [isMobileSettingsPopover, , closeMobileSettingsPopover, toggleMobileSettingsPopover] = useToggle(false);

  const { t } = useTranslation();

  const settingsBtnRef = useRef<HTMLButtonElement>(null);
  const popoverContentWrapper = useRef<HTMLDivElement>(null);

  const onOutsideClick = (event: MouseEvent) => {
    if (
      !settingsBtnRef.current?.contains(event.target as Node) &&
      !(event.target as HTMLElement).className.includes('tooltip-inner')
    ) {
      closeMobileSettingsPopover();
    }
  };

  useOnClickOutside(popoverContentWrapper, onOutsideClick);

  if (!contextValue) return null;

  return (
    <>
      <IconButton
        innerRef={settingsBtnRef}
        className={cn(styles.chat__header__icon, 'd-sm-none', {
          [styles.chat__header__icon_active]: contextValue.messagesTruncationMode,
        })}
        data-test-id='Chat.name_btn_edit_mobile'
        name='farEllipsisH'
      />
      <Popover
        target={settingsBtnRef}
        isOpen={isMobileSettingsPopover}
        placement='bottom'
        toggle={toggleMobileSettingsPopover}
        hideArrow={true}
        withoutBoxShadow={true}
        popperClassName={styles.chat__header__popover}
        positionFixed
      >
        <div ref={popoverContentWrapper} className=''>
          <div className={styles.chat__header__popover__context}>
            <div className='flex justify-between mb-4'>
              <p>
                {t('ChatHeader:context')}:{' '}
                <b className={cn({ 'text-danger': contextValue.messagesTruncationMode })}>{contextValue.fullness}%</b>
              </p>
              <div className={styles.chat__header__qIcon} id='chatHeaderContextIconMobile'>
                <Icon className='flex' name='faQuestion' />
              </div>
              <Tooltip autohide={false} fade={false} target='chatHeaderContextIconMobile' positionFixed>
                {t('ChatHeader:contextTooltip')}
              </Tooltip>
            </div>
            <Button className='w-full' color='secondary' outline onClick={clearHistory}>
              {t('ChatHeader:clearHistory')}
            </Button>
          </div>
          <Button onClick={openSettings} className='mt-4 w-full justify-start' flat iconLeft='farPen' color='secondary'>
            {t('appSettings')}
          </Button>
          <Button onClick={deleteChat} className='mt-3 w-full justify-start' flat iconLeft='farTrashAlt' color='danger'>
            {t('deleteChat')}
          </Button>
        </div>
      </Popover>
    </>
  );
}
