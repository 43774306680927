import React, { memo, useMemo } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Message } from '../../types/chat';
import classes from './style.module.scss';

export interface Props {
  messageCreatedAt: Message['createdAt'];
  prevMessageCreatedAt?: Message['createdAt'];
}

export const DayOfMessageChanges = memo<Props>(({ prevMessageCreatedAt, messageCreatedAt }) => {
  const { getLocale } = useTranslation();

  const dayOfMessageChanges = useMemo(() => {
    if (!prevMessageCreatedAt) return;
    const prevDate = DateTime.fromMillis(prevMessageCreatedAt).startOf('day');
    const nowDate = DateTime.fromMillis(messageCreatedAt).startOf('day');
    if (nowDate.toMillis() === prevDate.toMillis()) return;
    return nowDate.toLocaleString({ ...DateTime.DATE_FULL, year: undefined }, { locale: getLocale() });
  }, [prevMessageCreatedAt, messageCreatedAt, getLocale]);

  if (!prevMessageCreatedAt || !dayOfMessageChanges) return null;

  return (
    <div className='margin-bottom-8'>
      <div className={cn('relative m-auto flex', classes.chat__message)}>
        <div className={classes.DayMessage}>{dayOfMessageChanges}</div>
      </div>
    </div>
  );
});
