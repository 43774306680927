import React, { FC } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { appOptions } from '../../api/cc.api';
import { useAppContext } from '../../contexts/appContext';
import styles from './style.module.scss';

type Props = {
  withLicense?: boolean;
};

export const ContentContainer: FC<Props> = ({ children, withLicense }) => {
  const {
    state: { lightMode },
  } = useAppContext();

  const { t } = useTranslation();

  return (
    <div className={cn(styles.contentContainer)}>
      <div className={cn('flex flex-1', styles.contentContainer_main)}>
        <div className={cn(`relative flex-1`, lightMode, styles.contentContainer_main_wrapper)}>{children}</div>
      </div>
      {withLicense && appOptions.value?.product?.tos && (
        <div className={styles.contentContainer__footer}>
          <a
            data-test-id='Account:ToSLink'
            href={appOptions.value?.product?.tos}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('license')}
          </a>
        </div>
      )}
    </div>
  );
};
export const ContentContainerWithRightBar: FC = ({ children }) => {
  return <div className={cn(styles.contentContainer_withRightbar)}>{children}</div>;
};

export const ContentContainerInner: FC<{ fullWidth: boolean }> = ({ children, fullWidth }) => {
  const {
    state: { lightMode },
  } = useAppContext();
  return (
    <div
      className={cn('flex flex-1', styles.contentContainer_withRightbar_main)}
      style={fullWidth ? { gridColumn: '1/-1' } : {}}
    >
      <div className={cn(`relative flex-1 overflow-hidden ${lightMode}`)}>{children}</div>
    </div>
  );
};
