import React from 'react';

import { isX5 } from '../../isX5';
import { t } from '../../localization';
import classes from './page403.module.scss';

export const Page403 = () => {
  return (
    <div className={classes.page403}>
      <div className={classes.page403_card}>
        <h3 className='mb-0'>{t(isX5 ? 'page403:Title' : 'accessDenied')}</h3>
        <p dangerouslySetInnerHTML={{ __html: t(`page403:Body${isX5 ? ':x5' : ''}`) }} />
      </div>
    </div>
  );
};
